
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, FormControl } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { AuthService } from "../../../../services/auth/auth.service";
import { EcRegistrationService } from "../../../../services/conference/ec/ec-registration.service"
import { User } from "../../../../models/user";
//import { Conference } from "../../../models/conference";
import { UserService } from "../../../../services/user/user.service";
import { MatRadioChange } from '@angular/material/radio';
import { MatStepper } from '@angular/material/stepper';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
//For Dialog
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig } from '@angular/material/dialog';
import { EventListenerFocusTrapInertStrategy } from '@angular/cdk/a11y';
import {
  MatSnackBar,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { EC22Conference } from 'src/app/models/conference/EC22Conference';
import { UserEditOthersProfileComponent } from '../../../dialog/user-edit-others-profile/user-edit-others-profile.component';
import { RelatedPerson } from "../../../../models/conference/RelatedPerson";
import { VolunteerSelection } from "../../../../models/conference/volunteerSelection";
import { UserConference } from 'src/app/models/conference/userConference';
import { MatTableDataSource } from '@angular/material/table';
import { AfterViewInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { CheckGroup } from '../../../../models/checkGroup';
import { MyAccountEditComponent } from 'src/app/components/dialog/EC/my-account-edit/my-account-edit.component';
import { ECConference } from 'src/app/models/conference/ECConference';

@Component({
  selector: 'app-ec-participant',
  templateUrl: './ec-participant.component.html',
  styleUrls: ['./ec-participant.component.css']
})
export class EcParticipantComponent implements OnInit {
  loaded: boolean = true;
  myFormGroup: FormGroup;
  checkGroup: CheckGroup = {
    id: null,
    value: null,
  };
  checkGroups: CheckGroup[] = [];

  // payments = [
  //   { value: "All", display: "All" },
  //   { value: "Completed", display: "Completed" },
  //   { value: "NotCompleted", display: "Not Completed" }
  // ]

  // beStudents = [
  //   { value: null, display: "" },
  //   { value: true, display: "Yes" },
  //   { value: false, display: "No" }
  // ]
  programTypes = [
    { value: null, display: "" },
    { value: "General Program", display: "General Program" },
    { value: "Mission Program", display: "Mission Program" },
    { value: "Seekers Program", display: "Seekers Program" },
    { value: "Youth Program", display: "Youth Program" }
  ];
  genders = [
    { value: null, display: "" },
    { value: "Male", display: "男性" },
    { value: "Female", display: "女性" },
  ];
  languages = [
    { value: null, display: "" },
    { value: "Japanese", display: "日本語" },
    { value: "English", display: "英語" },
    { value: "Bilingual", display: "バイリンガル" },
  ]
  attendJapanRegionalMeetings = [
    { value: null, display: "" },
    { value: true, display: "参加" },
    { value: false, display: "不参加" },
  ]
  attendEnglishPartners = [
    { value: null, display: "" },
    { value: true, display: "参加" },
    { value: false, display: "不参加" },
  ]
  attendCounselings = [
    { value: null, display: "" },
    { value: true, display: "参加" },
    { value: false, display: "不参加" },
  ]
  needComingBuss = [
    { value: null, display: "" },
    { value: true, display: "必要" },
    { value: false, display: "不要" },
  ]
  needReturningBuss = [
    { value: null, display: "" },
    { value: true, display: "必要" },
    { value: false, display: "不要" },
  ]
  // stayDec27s = [
  //   { value: null, display: "" },
  //   { value: "StayDec27", display: "する" },
  //   { value: "NoParticipateDec27", display: "しない" },
  // ]
  volunteers = [
    { value: null, display: "" },
    { value: 'Prayer', display: 'Prayer' },
    { value: 'Praise Team', display: 'Praise Team' },
    { value: 'Bible Reading', display: 'Bible Reading' },
    { value: 'Skit', display: 'Skit' },
    { value: 'PA', display: 'PA' },
    { value: 'Propresenter', display: 'Propresenter' },
    { value: 'Video/Photo', display: 'Video/Photo' },
    { value: 'Translator', display: 'Translator' },
    { value: 'Interpreter', display: 'Interpreter' },
    { value: 'Usher', display: 'Usher' },
    { value: 'Small Group Leader', display: 'Small Group Leader' },
    { value: 'Workshop Helper', display: 'Workshop Helper' },
    { value: 'Kids Program Helper', display: 'Kids Program Helper' },
    { value: 'Book Store', display: 'Book Store' },
    { value: 'Nursing', display: 'Nursing' },
    { value: 'Reception', display: 'Reception' },
    { value: 'Counselor', display: 'Counselor' },
    { value: 'Sharing Testimony', display: 'Sharing Testimony' }
  ]
  listVolunteers: CheckGroup[] =
    [
      { id: 0, value: 'Prayer' },
      { id: 1, value: 'Praise Team' },
      { id: 2, value: 'Bible Reading' },
      { id: 3, value: 'Skit' },
      { id: 4, value: 'PA' },
      { id: 5, value: 'Propresenter' },
      { id: 6, value: 'Video/Photo' },
      { id: 7, value: 'Translator' },
      { id: 8, value: 'Interpreter' },
      { id: 9, value: 'Usher' },
      { id: 10, value: 'Small Group Leader' },
      { id: 11, value: 'Workshop Helper' },
      { id: 12, value: 'Kids Program Helper' },
      { id: 13, value: 'Book Store' },
      { id: 14, value: 'Nursing' },
      { id: 15, value: 'Reception' },
      { id: 16, value: 'Counselor' },
      { id: 17, value: 'Sharing Testimony' }

    ]

  firstFormGroup: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  myMessage: string;
  mySelectedLanguage: string;
  cntUser: number;
  showNumberOfUser: boolean;
  payment: string;
  scholarship: string;
  includeFamilyVolunteer: boolean;

  //   displayedColumns: string[] = ['FirstName', 'LastName','Prefix', 'Email', 'Country','State','City',
  //   'EarlyBird','BeStudent','Online','ProgramType','DiscountItem','TotalAmount','PaymentDate','RegisteredDate',
  // 'AddToMailingList','ChurchName','MinistryName','FutureVolunteer'
  // ];

  displayedColumns: string[] = ['FirstName', 'LastName',
    'Email', 'Gender', 'Age', 'Student','Christian','ProgramType', 'SmallGroupLanguage',
    'AttendJapanRegionalMeeting', 'ApplyEnglishPartner','ApplyCounseling',
    'NeedCommingBus', 'NeedReturningBus', 'myVolunteerSelections','myRelatedPersons','registeredDate','myEditProfile'
  ];

  dataSource: MatTableDataSource<UserConference>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  user: User = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null,
    Occupation: null,
    MemberStatus: null,
    Married: null,
    ZoomAccount: null,
    Christian: null,
    Baptized: null,
    ReturneeInfo: null,
    HasReturningChurch: null,
    RegisteredDate: null,
    LastAccessedDate: null,
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //Added new
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm: null,
    MembershipType: null
  };
  relatedPerson: RelatedPerson = {
    Id: null,
    RelatedId: null,
    Email: null,
    FirstNameRelated: null,
    LastNameRelated: null,
    MyojiRelated: null,
    ShimeiRelated: null,
    DOBRelated: null,
    GenderRelated: null
  };
  volunteerSelection: VolunteerSelection={
    Id:0,
    UserId:null,
    Email:null,
    VolunteerName:null,
    VolunteerAccepted:null,
    AcceptedDate:null
  }

  relatedPersons: RelatedPerson[] = [];
  //volunteerSelections: VolunteerSelection[] = [];
  volunteerSelections: VolunteerSelection[]=[];
  conference: ECConference= {
    ECConferenceId:0,
    UserId: null,
    Email: null,
    UserName: null,
    FirstName: null,
    LastName: null,
    //Program
    ProgramType: null,
    SmallGroupLanguage: null,
    AttendJapanRegionalMeeting:null,
    JapanRegionalMeeting:null,
    ApplyEnglishPartner:null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others\
    Volunteers:null,
    Student:null,
    ApplyCounseling: null,
    MySleepingBag:null,
    BringExtraSleepingBag:null,
    CountExtraSleepingBag:null,
    NeedCommingBus:null,
    CommingBusDeparturePlace:null,
    CommingBusDepartureDateTime:null,
    CommingAirline:null,
    CommingFlightNo:null,
    DepartureCity:null,
    CommingInternationalDomestic:null,
    ArrivalDateTime:null,
    NeedReturningBus:null,
    ReturningBusDepartureDateTime:null,
    ReturningAirline:null,
    ReturningFlightNo:null,
    ReturningCity:null,
    ReturningBusDeparturePlace:null,
    ReturningInternationalDomestic:null,
    DepartureDateTime:null,
    RoomInfo:null,
    Couple:null,
    Youth:null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    RegisteredDate: null,
    UpdatedDate: null,
    AdminAccess: null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26:null,
    Dec27:null,
    Dec28:null,
    Dec29:null,
    Dec30:null,
    Dec31:null,
    KidGroup1:null,
    KidGroup2:null,
    KidGroup3:null,
    KidGroup4:null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections:this.volunteerSelections,
    BusComingConfirmation:null,
    BusReturningConfirmation:null,
    TermsOfParticipationVenue:null,
    TermsOfParticipationDateVenue:null,
    TermsOfParticipationYouth:null,
    TermsOfParticipationDateYouth:null,
    TermsOfParticipationYouthParent:null,
    TermsOfParticipationDateYouthParent:null,
    EmergencyContactFirstName:null,
    EmergencyContactLastName:null,
    EmergencyContactEmail:null,
    EmergencyContactTelNo:null,
    HasAllergy:null,
    AllergyDetail:null,
    BringInsurance:null,
    MinorHasCustodyInsurance:null,
    MinorHasCustodyInsuranceDate:null,
    InsuranceCompany:null,
    InsuranceAddress:null,
    InsuranceTelNo:null,
    InsurancePolicyNo:null,
    InsuranceGroupNo:null,
    VolunteerAgreeConductCode:null,
    VolunteerAgreeConductCodeDate:null,
    PayingAmount:null,
     //New
    BecomeMentor:null,
    ApplyMentoring:null,
    ReturningDepartureCity:null,
InsuranceStatus:null,
    DressCodeAgreeYouth:null,
    DressCodeDateYouth:null,
    DressCodeAgreeParent:null,
    DressCodeDateParent:null
}
  userConference: UserConference = {
    PasswordHash: null,
    Id: null,
    UserName: null,
    Email: null,
    //*** Customized by Oz
    FirstName: null,
    LastName: null,
    Password: null,
    Prefix: null,
    Shimei: null,
    Myoji: null,
    Street: null,
    Street2: null,
    City: null,
    State: null,
    Zip: null,
    Country: null,
    //Japan Address
    Yubin_Bango: null,
    To_Do_Fu_Ken: null,
    Shi_Gun_Ku: null,
    Cho_Son: null,
    Apartment_Etc: null,
    //Japan Address Kanji
    To_Do_Fu_Ken_JP: null,
    Shi_Gun_Ku_JP: null,
    Cho_Son_JP: null,
    Apartment_Etc_JP: null,
    //Other personal info
    Gender: null,
    DOB: null,
    TelNo: null,
    TimezoneName: null,
    UTCdiff: null,
    Token: null, //Need for password recovery
    Occupation: null,
    MemberStatus: null,
    Married: null,
    ZoomAccount: null,
    Christian: null,
    Baptized: null,
    ReturneeInfo: null,
    HasReturningChurch: null,
    RegisteredDate: null,
    LastAccessedDate: null,
    FaithQuesCompletedDate: null,
    //From siteground
    LoginId: null,
    MiddleNameEN: null,
    MiddleNameJP: null,
    PrefixJP: null,
    OccupationDetail: null,
    Organization: null,
    LivingCountry: null,
    PhoneEN: null,
    PhoneMobileEN: null,
    PhoneJP: null,
    PhoneMobileJP: null,
    ChurchNameEN: null,
    ChurchNameJP: null,
    MemberType: null,
    MemberDetail: null,
    MemberMemo: null,
    Shokaisha: null,
    Reason: null,
    NewsLetters: null,
    StaffReports: null,
    OtherLetters: null,
    NewsLetterLanguage: null,
    BasicRoles: null,
    //New addition
    MigrateFromSiteGround: null,
    AdminAccess: null,
    JCFNAccess: null,
    SupportingMember: null,
    LastAddressUpdatedDate: null,
    AcceptMembershipTerm: null,
    MembershipType: null,
    ECConferenceId: null,
    UserId: null,
    //Program
    ProgramType: null, //Japanese Adult, RJC, Seekers, Youth
    SmallGroupLanguage: null,//Japanese, English, Bilingual
    AttendJapanRegionalMeeting: null,
    JapanRegionalMeeting: null,
    ApplyEnglishPartner: null,
    //Payment
    ConferenceFee: null,
    ConferenceDiscountAmount: null,
    ConferenceFeePaidAmount: null,
    ConferenceFeePaidDate: null,
    CouponApproved: null,
    ApplyScholarship: null,
    ApplyingScholarshipAmount: null,
    ApprovedScholarshipAmount: null,
    ApplyingReason: null,
    //Others
    Volunteers: null,
    Student: null,
    ApplyCounseling: null,
    MySleepingBag: null,
    BringExtraSleepingBag: null,
    CountExtraSleepingBag: null,
    NeedCommingBus: null,
    CommingBusDeparturePlace: null,
    CommingBusDepartureDateTime: null,
    CommingAirline: null,
    CommingFlightNo: null,
    DepartureCity: null,
    CommingInternationalDomestic: null,
    ArrivalDateTime: null,
    NeedReturningBus: null,
    ReturningBusDeparturePlace: null,
    ReturningBusDepartureDateTime: null,
    ReturningAirline: null,
    ReturningFlightNo: null,
    ReturningCity: null,
    ReturningInternationalDomestic: null,
    DepartureDateTime: null,
    RoomInfo: null,
    Couple: null,
    Youth: null,
    YouthGrade: null,
    GuardianFirstName: null,
    GuardianLastName: null,
    GuardianMyoji: null,
    GuardianShimei: null,
    GuardianEmail: null,
    GuardianTelNo: null,
    TermsOfParticipation: null,
    TermsOfParticipationParent: null,
    TermsOfParticipationDate: null,
    TermsOfParticipationDateParent: null,
    //RegisteredDate?: Date;
    UpdatedDate: null,
    //AdminAccess:null,
    ECCommitteeAccess: null,
    Memo: null,
    Dec26: null,
    Dec27: null,
    Dec28: null,
    Dec29: null,
    Dec30: null,
    Dec31: null,
    KidGroup1: null,
    KidGroup2: null,
    KidGroup3: null,
    KidGroup4: null,
    myRelatedPersons: this.relatedPersons,
    myVolunteerSelections: this.volunteerSelections,
    BusComingConfirmation: null,
    BusReturningConfirmation: null,
    TermsOfParticipationVenue: null,
    TermsOfParticipationDateVenue: null,
    TermsOfParticipationYouth: null,
    TermsOfParticipationDateYouth: null,
    TermsOfParticipationYouthParent: null,
    TermsOfParticipationDateYouthParent: null,
    EmergencyContactFirstName: null,
    EmergencyContactLastName: null,
    EmergencyContactEmail: null,
    EmergencyContactTelNo: null,
    ApplicationFee: null,
    ApplicationFeePaidDate: null,
    BalanceAmount: null,
    BalanceAmountPaidDate: null,


    ////////////////////////
    mySelectedVolunteers: this.checkGroups,
    SmallGroupNo:null,
    RoomNo:null
  }
  userConferences: UserConference[];

  constructor(
    private _snackBar: MatSnackBar,
    private _formBuilder: FormBuilder,
    public authService: AuthService,
    public router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private ecRegistrationService: EcRegistrationService,
    private dialog: MatDialog,
  ) { }


  mySearch: any = {};

  ngOnInit(): void {
    this.mySelectedLanguage = this.router.url.includes('EN') == true ? "EN" : "JP";
    this.myFormGroup = this._formBuilder.group({
      myFirstName: [null],
      myLastName: [null],
      myEmail:[null],
      myGender: [null],
      myProgramType: [null],
      mySmallGroupLanguage: [null],
      myAttendingJapanRegionalMeeting: [null],
      myAttendingEnglishPartner: [null],
      myAttendingCounseling: [null],
      myNeedCommingBus: [null],
      myNeedReturningBus: [null],
      // myStayDec27: [null],
      myVolunteer: [null]
    });
    this.mySearch = JSON.parse(sessionStorage.getItem("mySearchingUser"));
    if (this.mySearch != null) {
      this.userConference.FirstName = this.mySearch.myProfileSearch.FirstName;
      this.userConference.LastName = this.mySearch.myProfileSearch.LastName;
      this.userConference.Email = this.mySearch.myProfileSearch.Email;
      this.userConference.ProgramType = this.mySearch.myProfileSearch.programTypes;
      this.userConference.SmallGroupLanguage = this.mySearch.myProfileSearch.SmallGroupLanguage;
      this.userConference.AttendJapanRegionalMeeting = this.mySearch.myProfileSearch.AttendJapanRegionalMeeting;
      this.userConference.ApplyEnglishPartner = this.mySearch.myProfileSearch.ApplyEnglishPartner;
      this.userConference.ApplyCounseling = this.mySearch.myProfileSearch.ApplyCounseling;
      this.userConference.NeedCommingBus = this.mySearch.myProfileSearch.needComingBus;
      this.userConference.NeedReturningBus = this.mySearch.myProfileSearch.needReturningBus;
      // this.userConference.Dec27 = this.mySearch.myProfileSearch.Dec27;
      this.userConference.myVolunteerSelections = this.mySearch.myProfileSearch.myVolunteerSelections;
      this.getUserConferenceBySearchKey(this.userConference);
    } else {
      //this.getUserConferenceBySearchKey(this.userConference);
    }
  }

  getMyLanguage(): string {
    return this.router.url.includes('EN') == true ? 'EN' : 'JP';
  }

  async getFormValue() {

    this.userConference.FirstName = (this.myFormGroup.get('myFirstName').value==null?null:(this.myFormGroup.get('myFirstName').value.trim()==""?null:(this.myFormGroup.get('myFirstName').value.trim())));
    this.userConference.LastName = (this.myFormGroup.get('myLastName').value==null?null:(this.myFormGroup.get('myLastName').value.trim()==""?null:(this.myFormGroup.get('myLastName').value.trim())));
    this.userConference.Email = (this.myFormGroup.get('myEmail').value==null?null:(this.myFormGroup.get('myEmail').value.trim()==""?null:(this.myFormGroup.get('myEmail').value.trim())));

    this.userConference.Gender = this.myFormGroup.get('myGender').value;
    this.userConference.ProgramType = this.myFormGroup.get('myProgramType').value;
    this.userConference.SmallGroupLanguage = this.myFormGroup.get('mySmallGroupLanguage').value;
    this.userConference.AttendJapanRegionalMeeting = this.myFormGroup.get('myAttendingJapanRegionalMeeting').value;
    this.userConference.ApplyEnglishPartner = this.myFormGroup.get('myAttendingEnglishPartner').value;
    this.userConference.ApplyCounseling = this.myFormGroup.get('myAttendingCounseling').value;
    this.userConference.NeedCommingBus = this.myFormGroup.get('myNeedCommingBus').value;
    this.userConference.NeedReturningBus = this.myFormGroup.get('myNeedReturningBus').value;
    // this.userConference.Dec27 = this.myFormGroup.get('myStayDec27').value;
   // this.userConference.myVolunteerSelections = this.myFormGroup.get('myVolunteer').value;

    // this.conference.FirstName = this.myFormGroup.get('myFirstName').value;
    // this.conference.LastName = this.myFormGroup.get('myLastName').value;
    // this.conference.Email = this.myFormGroup.get('myEmail').value;
    // //this.conference.Gender = this.myFormGroup.get('myGender').value;
    // this.conference.ProgramType = this.myFormGroup.get('myProgramType').value;
    // this.conference.SmallGroupLanguage = this.myFormGroup.get('mySmallGroupLanguage').value;
    // this.conference.AttendJapanRegionalMeeting = this.myFormGroup.get('myAttendingJapanRegionalMeeting').value;
    // this.conference.ApplyEnglishPartner = this.myFormGroup.get('myAttendingEnglishPartner').value;
    // this.conference.ApplyCounseling = this.myFormGroup.get('myAttendingCounseling').value;
    // this.conference.NeedCommingBus = this.myFormGroup.get('myNeedCommingBus').value;
    // this.conference.NeedReturningBus = this.myFormGroup.get('myNeedReturningBus').value;
    // this.conference.Dec27 = this.myFormGroup.get('myStayDec27').value;
  //this.conference.myVolunteerSelections = this.myFormGroup.get('myVolunteer').value;
  //this.conference.myVolunteerSelections = this.volunteerSelections
  //this.userConference.myVolunteerSelections = this.volunteerSelections;
 if(this.myFormGroup.get('myVolunteer').value!=null){
  console.log(this.myFormGroup.get('myVolunteer').value.length);
  this.myFormGroup.get('myVolunteer').value.forEach((element: string) => {
    debugger
    //console.log("my volunteer!:"+ element);
    var myVolunteer = this.volunteerSelection;
    myVolunteer.VolunteerName = element;
    //this.volunteerSelection.VolunteerName =element;
    console.log("volunteerName:"+this.volunteerSelection.VolunteerName)
    console.log("volunteerName:"+myVolunteer)
    this.conference.myVolunteerSelections.push(myVolunteer);
    this.userConference.myVolunteerSelections.push(myVolunteer);
  });

this.conference.myVolunteerSelections.forEach((element: { VolunteerName: string; }) => {
  console.log('my:'+element.VolunteerName)
});


  //this.conference.myVolunteerSelections = this.volunteerSelections;
  //this.userConference.myVolunteerSelections = this.volunteerSelections;
 }else{
  console.log('no volunteer selected');
 }

  // console.log("check: "+ this.myFormGroup.get('myVolunteer').value);
  // this.conference.myVolunteerSelections.forEach(item=>{
  //   console.log("test:" + item);
  //   console.log("test2:" + item.VolunteerName);

  //   this.volunteerSelection.VolunteerName =item;
  //    this.volunteerSelections.push(this.volunteerSelection);
  // }

  //   this.volunteerSelections=[]
  //   this.conference.myVolunteerSelections= null;
  //   this.userConference.myVolunteerSelections=null;
  //   $event.value.forEach((item: string) => {
  //     console.log("selected object:"+ item)
  //     //this.volunteerSelection=null;
  //     this.volunteerSelection.VolunteerName =item
  //     this.volunteerSelections.push(this.volunteerSelection);
  //   });

  return this.userConference;
  }

  async onSearchSubmit({ value, valid }: { value: UserConference; valid: boolean }) {
    this.loaded = false;
    this.userConference = await this.getFormValue();
    this.cntUser = 0;
    // alert(this.userConference.FirstName)
    // this.userConference.FirstName =
    //   value.FirstName == null
    //     ? null
    //     : value.FirstName.trim() == ""
    //       ? null
    //       : value.FirstName.trim();
    // this.userConference.LastName =
    //   value.LastName == null
    //     ? null
    //     : value.LastName.trim() == ""
    //       ? null
    //       : value.LastName.trim();
    // if (!valid) {
    //   this.myMessage = "Please fill out the form correctly"
    //   this.openSnackBar('error', 'close');
    //   this.loaded = true;
    // } else {
      sessionStorage.removeItem("mySearchingUser");
      sessionStorage.setItem(
        "mySearchingUser",
        JSON.stringify({ myProfileSearch: this.userConference })
      );
      this.getUserConferenceBySearchKey(this.userConference);
   // }
    // sessionStorage.removeItem("mySearchingUser");
    // sessionStorage.setItem(
    //   "mySearchingUser",
    //   JSON.stringify({ myProfileSearch: this.userConference })
    // );
    // this.getUserConferenceBySearchKey(this.userConference)
  }

  getUserConferenceBySearchKey(userConference: UserConference) {
    this.loaded = false;
    this.showNumberOfUser = false;
    this.conference.FirstName = this.userConference.FirstName;
    this.conference.LastName = this.userConference.LastName;
    this.conference.Email = this.userConference.Email;
    this.conference.ProgramType = this.userConference.ProgramType;
    this.conference.SmallGroupLanguage = this.userConference.SmallGroupLanguage;
    this.conference.AttendJapanRegionalMeeting = this.userConference.AttendJapanRegionalMeeting;
    this.conference.ApplyEnglishPartner = this.userConference.ApplyEnglishPartner;
    this.conference.ApplyCounseling = this.userConference.ApplyCounseling;
    this.conference.NeedCommingBus = this.userConference.NeedCommingBus;
    this.conference.NeedReturningBus = this.userConference.NeedReturningBus;
    this.conference.myVolunteerSelections = this.userConference.myVolunteerSelections;
    // this.ec22RegistrationService.getUserConferenceBySearchKey(this.userConference)

console.log('#############################');
console.log(this.conference.Email);
    this.ecRegistrationService.GetUserConferenceBySearchKey(this.conference,this.includeFamilyVolunteer)
      .subscribe((results: UserConference[]) => {
        this.userConferences = results;
        // this.userConferences.forEach(x=>
        //   x.myVolunteerSelections.forEach(y=>
        //     console.log(y.VolunteerName)))

        // Assign the data to the data source for the table to render
        this.dataSource = new MatTableDataSource(this.userConferences);
        //this.dataSource
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.loaded = true;
        var tempEmail: any;
        var cnt = 0;
        this.userConferences.forEach(element => {
          if (tempEmail != element) {
            tempEmail = element.Email;
            cnt++;
          }
          this.cntUser = cnt;
        });
        this.loaded = true;
        this.showNumberOfUser = true;
      },
      (        error: any) => {
        this.myMessage = "Data search failed."
        this.openSnackBar('error', 'close');
        this.loaded = true;
        this.showNumberOfUser = true
      }
      );
  }

  onChangeVolunteer(value:boolean){
    console.log('Include family & volunteer?: '+value);
    this.includeFamilyVolunteer=value;
  }


  onCancel($event: any) {
    event.preventDefault(); // This is neccessary to prevent cencel button submit form
    this.userConference.FirstName = null;
    this.userConference.LastName = null;
    this.userConference.Email = null;
    this.userConference.Gender = null;
    this.userConference.ProgramType = null;
    this.userConference.ApplyCounseling = null;
    this.userConference.AttendJapanRegionalMeeting = null;
    this.userConference.ApplyEnglishPartner = null;
    // this.userConference.Dec27;
    this.myFormGroup.patchValue({
      myFirstName: null,
      myLastName: null,
      myEmail:null,
      myGender: null,
      myProgramType: null,
      mySmallGroupLanguage: null,
      myAttendingJapanRegionalMeeting: null,
      myAttendingEnglishPartner: null,
      myAttendingCounseling: null,
      myNeedCommingBus: null,
      myNeedReturningBus: null,
      // myStayDec27: null,
      myVolunteer: null

    });

    sessionStorage.removeItem("mySearchingUser");
  }

  openSnackBar(message: string, action: string) {
    if (message == "success") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Success"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    } else if (message = "error") {
      this._snackBar.open(this.myMessage, action, {
        duration: 10000,
        panelClass: ["customPanel_Error"],
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
      });
    }
  }

  calculate_age(dob: Date) {
    var diff_ms = Date.now() - new Date(dob).getTime();
    var age_dt = new Date(diff_ms);
    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  onSelectVolunteer($event: any) {
    // this.volunteerSelections=[]
    // this.conference.myVolunteerSelections= null;
    // this.userConference.myVolunteerSelections=null;
    // $event.value.forEach((item: string) => {
    //   console.log("selected object:"+ item)
    //   //this.volunteerSelection=null;
    //   this.volunteerSelection.VolunteerName =item
    //   this.volunteerSelections.push(this.volunteerSelection);
    // });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
  //   displayedColumns1: string[] = ['FirstName', 'LastName','Prefix', 'Email', 'Country','State','City',
  //   'EarlyBird','BeStudent','Online','ProgramType','DiscountItem','TotalAmount','PaymentDate','RegisteredDate',
  //   'AddToMailingList','ChurchName','MinistryName','FutureVolunteer'
  // ];

  onSubmit() {
  }

  onClickGoToMyAccount(row: UserConference) {
    sessionStorage.removeItem("searchingMemberId");
    sessionStorage.setItem("searchingMemberId", row.Id);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    dialogConfig.maxHeight = '800px'; //Desktop
    dialogConfig.maxWidth = '1200px';//Desktoop
    dialogConfig.height = '100%';//Mobile
    dialogConfig.width = '100%';//Mobile
    dialogConfig.panelClass = 'custom-modalbox'; //Refer global level
    dialogConfig.data = { userId: row.UserId };
    const dialogRef = this.dialog.open(MyAccountEditComponent, dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      var confirmedAction = result;
      if (result != null) {
        console.log("Confirmed Action: " + confirmedAction);
        console.log("returned AS400Code: " + confirmedAction.AS400Code);
      }
    })
  }

  exportToExcel() {
  var headers={
    FirstName: "First Name",
    LastName: "Last Name",
    Shimei:"名",
    Myoji:"姓",
    Email: "Email",
    Gender: "Gender",
    Age: "Age",
    Student:"学生",
    Christian:"クリスチャン",
    ProgramType:"プログラム",
    SmallGroupLanguage:"言語",
    AttendJapanRegionalMeetings:"日本地域別集会",
    AttendEnglishPartners:"イングリッシュパートナー",
    // NeedComingBuss:"行きのバス",
    // NeedReturningBuss:"帰りのバス",
    //Dec27:"前泊",
    Volunteers:"奉仕",
    RelatedPersons:"同伴者",
    RegisteredDate:"登録日",
    ConferenceFee:"参加費",
    ConferenceFeePaidDate:"参加費支払日",
    ApplicationFee:"申込金",
    ApplicationFeePaidDate:"申込金支払日",
    ReturneeInfo:"帰国情報",
    NeedCommingBus:"行きバス必要",
    CommingBusDeparturePlace: "行きバス名",
    //CommingBusDepartureDateTime: "行きバス出発時間",
    CommingAirline: "行き航空会社",
    CommingFlightNo: "行き便名",
    DepartureCity: "行き出発都市",
    CommingInternationalDomestic: "行き国内国際",
    ArrivalDateTime: "行き到着時間",

   NeedReturningBus: "帰りバス必要",
    ReturningBusDeparturePlace: "帰りバス名",
    //ReturningBusDepartureDateTime: "帰りバス出発時間",
    ReturningAirline: "帰り航空会社",
    ReturningFlightNo: "帰り便名",
    ReturningCity: "帰り目的都市",
    ReturningInternationalDomestic: "帰り国内国際",
    DepartureDateTime: "帰り出発時間",
    GuardianFirstName: "ユース親名前",
    GuardianLastName: "ユース親苗字",
    GuardianEmail: "ユース親Eメール",
    GuardianTelNo: "ユース親TeL＃",
    LivingCountry: "居住国",
    SmallGroupNo:"スモールグループ番号",
    RoomNo:"部屋番号",
    ConferenceFee2:"参加費",
    ConferenceDiscountAmount:"割引",
    ConferenceFeePaidAmount:"支払い額",
    ConferenceFeePaidDate2:"支払い実行日",
    ApprovedScholarshipAmount:"奨学金",
    ApplicationFee2:"申込金",
    BalanceAmountToPay:"残額"
  };
  var itemsFormatted:any=[];
  this.userConferences.forEach(item=>{
    var dt = new Date(item.RegisteredDate);
    var createdMonth = dt.getMonth()+1;
    var createdYear = dt.getFullYear();
    var createdDay = dt.getDate();

    var conferenceFeePaidDate = new Date(item.ConferenceFeePaidDate);
    var conferenceFeePaidMonth = conferenceFeePaidDate.getMonth()+1;
    var conferenceFeePaidYear = conferenceFeePaidDate.getFullYear();
    var conferenceFeePaidDay = conferenceFeePaidDate.getDate();

    var applicationFeePaidDate = new Date(item.ApplicationFeePaidDate);
    var applicationFeePaidMonth = applicationFeePaidDate.getMonth()+1;
    var applicationFeePaidYear  = applicationFeePaidDate.getFullYear();
    var applicationFeePaidDay   = applicationFeePaidDate.getDate();

    var CommingBusDepartureDate = new Date(item.CommingBusDepartureDateTime);
    var CommingBusDepartureMonth = CommingBusDepartureDate.getMonth()+1;
    var CommingBusDepartureYear  = CommingBusDepartureDate.getFullYear();
    var CommingBusDepartureDay   = CommingBusDepartureDate.getDate();


    var ArrivalDate = new Date(item.ArrivalDateTime);
    var ArrivalMonth = ArrivalDate.getMonth()+1;
    var ArrivalYear  = ArrivalDate.getFullYear();
    var ArrivalDay   = ArrivalDate.getDate();
    var ArrivalHours = ArrivalDate.getHours();
    var ArrivalMinutes= ArrivalDate.getMinutes();

    var ReturningBusDepartureDate = new Date(item.ReturningBusDepartureDateTime);
    var ReturningBusDepartureMonth = ReturningBusDepartureDate.getMonth()+1;
    var ReturningBusDepartureYear  = ReturningBusDepartureDate.getFullYear();
    var ReturningBusDepartureDay   = ReturningBusDepartureDate.getDate();

    var DepartureDate = new Date(item.DepartureDateTime);
    var DepartureMonth = DepartureDate.getMonth()+1;
    var DepartureYear  = DepartureDate.getFullYear();
    var DepartureDay   = DepartureDate.getDate();
    var DepartureHours   = DepartureDate.getHours();
    var DepartureMinutes   = DepartureDate.getMinutes();

    var myVolunteerLists:string='';
    if(item.myVolunteerSelections!=null){
      item.myVolunteerSelections.forEach(item =>
        myVolunteerLists = myVolunteerLists +item.VolunteerName+";"
      );
    }
    var myRelatedPersons:string='';
    if(item.myRelatedPersons!=null){
      item.myRelatedPersons.forEach(item =>
        myRelatedPersons = myRelatedPersons+item.FirstNameRelated+' '+item.LastNameRelated+";"
      );
    }

    itemsFormatted.push({
      FirstName: item.FirstName == null ? "" :item.FirstName.replace(/,/g, " "),
      LastName: item.LastName == null ? "" :item.LastName.replace(/,/g, " "),
      Shimei:item.Shimei==null?" ":item.Shimei.replace(/,/g, ""),
      Myoji: item.Myoji==null?" ":item.Myoji.replace(/,/g, ""),
      Email: item.Email == null ? "" : item.Email.replace(/,/g, " "),
      Gender: item.Gender == null ? "" : item.Gender=='Male'?'男性':'女性',
      Age: item.DOB == null ? "" : this.calculate_age(item.DOB),
      Student:item.Student==true?'はい':'いいえ',
      Christian:item.Christian==true?'はい':'いいえ',
      ProgramType: item.ProgramType == null ? "" :item.ProgramType=='JapaneseAdult'?'一般':(item.ProgramType=='RJC'?'English':item.ProgramType),
      SmallGroupLanguage: item.SmallGroupLanguage == null ? "" :item.SmallGroupLanguage=='Japanese'?'日本語':item.SmallGroupLanguage,
      AttendJapanRegionalMeetings: item.AttendJapanRegionalMeeting == null ? "" :item.AttendJapanRegionalMeeting==true?'参加':'不参加'+" "+item.JapanRegionalMeeting,
      AttendEnglishPartners:item.ApplyEnglishPartner==null?"":item.ApplyEnglishPartner==true?'参加':'不参加',
      //  NeedComingBuss:item.NeedCommingBus==null?"":item.NeedCommingBus==true?'必要':'不要',
      //  NeedReturningBuss:item.NeedReturningBus==null?"":item.NeedReturningBus==true?'必要':'不要',
       //Dec27:item.Dec27==null?"":item.Dec27=="StayDec27"?'する':'しない',
      Volunteers:myVolunteerLists==null?"":myVolunteerLists,
      RelatedPersons:myRelatedPersons==null?"":myRelatedPersons,
      RegisteredDate:item.RegisteredDate==null?"":createdMonth+"/"+createdDay+"/"+createdYear,
      ConferenceFee:item.ConferenceFee==null?"":item.ConferenceFee,
      ConferenceFeePaidDate:item.ConferenceFeePaidDate==null?"":conferenceFeePaidMonth+"/"+conferenceFeePaidDay+"/"+conferenceFeePaidYear,
      ApplicationFee:item.ApplicationFee==null?"":item.ApplicationFee,
      ApplicationFeePaidDate:item.ApplicationFeePaidDate==null?"":applicationFeePaidMonth+"/"+applicationFeePaidDay+"/"+applicationFeePaidYear,
      ReturneeInfo:item.ReturneeInfo== null ? "" :item.ReturneeInfo.replace(/,/g, " "),

      NeedCommingBus:item.NeedCommingBus==true?'はい':'いいえ',
      CommingBusDeparturePlace: item.CommingBusDeparturePlace == null ? "" :item.CommingBusDeparturePlace.replace(/,/g, " "),
      //CommingBusDepartureDateTime: item.CommingBusDepartureDateTime==null?"":CommingBusDepartureMonth+"/"+CommingBusDepartureDay+"/"+CommingBusDepartureYear,
      CommingAirline:item.CommingAirline == null ? "" :item.CommingAirline.replace(/,/g, " "),
      CommingFlightNo: item.CommingFlightNo == null ? "" :item.CommingFlightNo.replace(/,/g, " "),
      DepartureCity: item.DepartureCity == null ? "" :item.DepartureCity.replace(/,/g, " "),
      CommingInternationalDomestic: item.CommingInternationalDomestic == null ? "" :item.CommingInternationalDomestic.replace(/,/g, " "),
      ArrivalDateTime: item.ArrivalDateTime==null?"":ArrivalMonth+"/"+ArrivalDay+"/"+ArrivalYear+" "+ArrivalHours+":"+ArrivalMinutes,

     NeedReturningBus: item.NeedReturningBus==true?'はい':'いいえ',
      ReturningBusDeparturePlace: item.ReturningBusDeparturePlace == null ? "" :item.ReturningBusDeparturePlace.replace(/,/g, " "),
      //ReturningBusDepartureDateTime: item.ReturningBusDepartureDateTime==null?"":ReturningBusDepartureMonth+"/"+ReturningBusDepartureDay+"/"+ReturningBusDepartureYear,
      ReturningAirline: item.ReturningAirline == null ? "" :item.ReturningAirline.replace(/,/g, " "),
      ReturningFlightNo: item.ReturningFlightNo == null ? "" :item.ReturningFlightNo.replace(/,/g, " "),
      ReturningCity: item.ReturningCity == null ? "" :item.ReturningCity.replace(/,/g, " "),
      ReturningInternationalDomestic:  item.ReturningInternationalDomestic == null ? "" :item.ReturningInternationalDomestic.replace(/,/g, " "),
      DepartureDateTime: item.DepartureDateTime==null?"":DepartureMonth+"/"+DepartureDay+"/"+DepartureYear+" "+DepartureHours+":"+DepartureMinutes,
      GuardianFirstName: item.GuardianFirstName== null ? "" :item.GuardianFirstName.replace(/,/g, " "),
      GuardianLastName: item.GuardianLastName== null ? "" :item.GuardianLastName.replace(/,/g, " "),
      GuardianEmail: item.GuardianEmail== null ? "" :item.GuardianEmail.replace(/,/g, " "),
      GuardianTelNo: item.GuardianTelNo== null ? "" :item.GuardianTelNo.replace(/,/g, " "),
      LivingCountry: item.LivingCountry== null ? "" :item.LivingCountry.replace(/,/g, " "),
      SmallGroupNo: item.SmallGroupNo== null ? "" :item.SmallGroupNo.replace(/,/g, " "),
      RoomNo:       item.RoomNo== null ? "" :item.RoomNo.replace(/,/g, " "),
      ConferenceFee2:item.ConferenceFee==null?0:item.ConferenceFee,
      ConferenceDiscountAmount:item.ConferenceDiscountAmount==null?0:item.ConferenceDiscountAmount,
      ConferenceFeePaidAmount:item.ConferenceFeePaidAmount==null?0:item.ConferenceFeePaidAmount,
      ConferenceFeePaidDate2:item.ConferenceFeePaidDate==null?"":conferenceFeePaidMonth+"/"+conferenceFeePaidDay+"/"+conferenceFeePaidYear,
      ApprovedScholarshipAmount:item.ApprovedScholarshipAmount==null?0:item.ApprovedScholarshipAmount,
      ApplicationFee2:item.ApplicationFee==null?0:item.ApplicationFee,

      BalanceAmountToPay:item.ConferenceFee
      -(item.ConferenceDiscountAmount==null?0:item.ConferenceDiscountAmount)
      -(item.ConferenceFeePaidAmount!=null && item.ConferenceFeePaidDate!=null?item.ConferenceFeePaidAmount:0)
      -(item.ConferenceFeePaidAmount!=null && item.ConferenceFeePaidDate!=null? 0:(item.ApprovedScholarshipAmount!=null?item.ApprovedScholarshipAmount:0))
      -(item.ConferenceFeePaidAmount!=null && item.ConferenceFeePaidDate!=null?0:(item.ApplicationFee!=null?item.ApplicationFee:0))
    });
  });
  var fileTitle = "EC_Registration";
  this.exportCSVFile(headers, itemsFormatted, fileTitle);
  }

  exportCSVFile(headers: any, items: any, fileTitle: any) {
    if (headers) {
      items.unshift(headers);
    }
    // Convert Object to JSON
    var jsonObject = JSON.stringify(items);
    var csv = this.convertToCSV(jsonObject);
    var exportedFilenmae = fileTitle + ".csv" || "export.csv";
    var blob = new Blob(
      [
        "\uFEFF", // 文字化け防止
        csv],
      { type: "text/csv;charset=utf-8;" });

      var link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", exportedFilenmae);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

  }

  convertToCSV(objArray: any) {
    var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
    var str = "";
    for (var i = 0; i < array.length; i++) {
      var line = "";
      for (var index in array[i]) {
        if (line != "") line += ",";
        line += array[i][index];
      }
      str += line + "\r\n";
    }
    return str;
  }
}
