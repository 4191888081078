
<div class="myBase">
  <div class="myBackgroundLayer">
      <div class="myContainer">
          <div class="row truckingTitle">
              <div class="col-md-6">
                  <h2><i class="fas man fa-2x"></i>&nbsp; EC Registrant</h2>
              </div>
          </div>
          <!--  ####### Search Box Area ######## -->
       <div>
            <form
            [formGroup]="myFormGroup"
          >
              <div class=" divSearchBox">
                <div class="row divSeachBoxSub">
                  <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                      <mat-label>First Name</mat-label>
                      <input formControlName="myFirstName" name="FirstName" matInput placeholder="First Name"
                        class="matInputBox" />
                    </mat-form-field>
                  </div>
                  <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                      <mat-label>Last Name</mat-label>
                      <input formControlName="myLastName" name="LastName" matInput placeholder="Last Name"
                        class="matInputBox" />
                    </mat-form-field>
                  </div>
                  <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                      <mat-label>Email</mat-label>
                      <input formControlName="myEmail" name="Email" matInput placeholder="Email"
                        class="matInputBox" />
                    </mat-form-field>
                  </div>
                  <!-- <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                      <mat-label>Gender</mat-label>
                      <mat-select name="SelectedStudent" formControlName="myGender" class="matInputBox">
                        <mat-option *ngFor="let gender of genders" [value]="gender.value" class="matInputBox">
                          {{ gender.display }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div> -->
                  <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                      <mat-label>参加プログラム</mat-label>
                      <mat-select name="SelectedProgramType" formControlName="myProgramType" class="matInputBox">
                        <mat-option *ngFor="let programType of programTypes" [value]="programType.value" class="matInputBox">
                          {{ programType.display }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                      <mat-label>スモールグループ言語</mat-label>
                      <mat-select name="SelectedSmallGroupLanguage" formControlName="mySmallGroupLanguage" class="matInputBox">
                        <mat-option *ngFor="let language of languages" [value]="language.value" class="matInputBox">
                          {{ language.display }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                      <mat-label>日本地域別集会参加</mat-label>
                      <mat-select name="SelectedAttendingJapanRegionalMeeting" formControlName="myAttendingJapanRegionalMeeting" class="matInputBox">
                        <mat-option *ngFor="let attendJapanRegionalMeeting of attendJapanRegionalMeetings" [value]="attendJapanRegionalMeeting.value" class="matInputBox">
                          {{ attendJapanRegionalMeeting.display }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                      <mat-label>イングリッシュパートナープログラム</mat-label>
                      <mat-select name="SelectedAttendingEnglishPartner" formControlName="myAttendingEnglishPartner" class="matInputBox">
                        <mat-option *ngFor="let attendEnglishPartner of attendEnglishPartners" [value]="attendEnglishPartner.value" class="matInputBox">
                          {{ attendEnglishPartner.display }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                      <mat-label>カウンセリング</mat-label>
                      <mat-select name="SelectedAttendingCounseling" formControlName="myAttendingCounseling" class="matInputBox">
                        <mat-option *ngFor="let attendCounseling of attendCounselings" [value]="attendCounseling.value" class="matInputBox">
                          {{ attendCounseling.display }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                      <mat-label>行きのバス</mat-label>
                      <mat-select name="SelectedNeedCommingBus" formControlName="myNeedCommingBus" class="matInputBox">
                        <mat-option *ngFor="let needComingBus of needComingBuss" [value]="needComingBus.value" class="matInputBox">
                          {{ needComingBus.display }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                      <mat-label>帰りのバス</mat-label>
                      <mat-select name="SelectedNeedReturningBu" formControlName="myNeedReturningBus" class="matInputBox">
                        <mat-option *ngFor="let needReturningBus of needReturningBuss" [value]="needReturningBus.value" class="matInputBox">
                          {{ needReturningBus.display }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <!-- <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                      <mat-label>前泊</mat-label>
                      <mat-select name="SelectedStayDec27" formControlName="myStayDec27" class="matInputBox">
                        <mat-option *ngFor="let stayDec27 of stayDec27s" [value]="stayDec27.value" class="matInputBox">
                          {{ stayDec27.display }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div> -->
                  <!-- <div class="col searchItem">
                    <mat-form-field class="containerSearchLg" appearance="outline">
                        <mat-label>奉仕</mat-label>
                        <mat-select name="SelectedVolunteer" formControlName="myVolunteer"
                            multiple class="matInputBox" (selectionChange)="onSelectVolunteer($event)">
                            <mat-option *ngFor="let listVolunteer of listVolunteers"
                                [value]="listVolunteer.value" class="matInputBox">
                                {{ listVolunteer.value }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->

                </div>
                <div class=" divSeachBoxButtons">


                  <div class="row" id="btnGroup">
                    <div class="col">
                      <button type="button" class="btn btn-link linkToDownload" (click)="onSearchSubmit(myFormGroup)">
                       参加者全員を出す
</button>



                    </div>
                    <div  class="col">
                      <mat-checkbox #checkboxIncludeVolunteer
                                          (change)='onChangeVolunteer(checkboxIncludeVolunteer.checked)' class="ckb_SG"
                                          > {{this.router.url.includes('EN')==true?'Include Family & Volunteer':'家族 奉仕を含む'}}
                                        </mat-checkbox>
                  </div>
                    <div class="col">
                      <button [disabled]="!myFormGroup.valid"
                      (click)="onSearchSubmit(myFormGroup)"
                      class="btn btn-primary btnInSearchBox">
                        <i class="fa fa-search fa-fw"></i> Search</button>
                    </div>

                    <div class="col">
                      <button (click)="onCancel($event)"
                        class="btn btn-secondary  btnInSearchBox">
                        Clear
                      </button>
                    </div>
                    <div class="col">
                      <button mat-button (click)="exportToExcel()" class="btn btn-success btnDownload">
                        Download
                      </button>
                    </div>
                    <div class="col">
                      <h4 *ngIf="showNumberOfUser" id="numberOfRegistrant">
                        {{ cntUser }} Registrant(s) Found
                      </h4>
                    </div>

                  </div>
                </div>

              </div>
            </form>
          </div>



          <div>
            <h4 *ngIf="!loaded" class="table loading-message">Loading data...</h4>
            <div *ngIf="!loaded">
              <mat-progress-bar mode="indeterminate"></mat-progress-bar>
            </div>
            <br>
            <div class="registrantList">
              <div>
                <div>
                    <table class="table table-striped table-bordered table-hover tblRegistrant" mat-table
                    [dataSource]="dataSource" matSort>
                    <ng-container matColumnDef="FirstName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                        First Name</th>
                      <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.FirstName}}<br>{{row.Shimei}} </td>
                    </ng-container>

                    <ng-container matColumnDef="LastName">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                        Last Name</th>
                      <td mat-cell *matCellDef="let row" class="columnMdTd"> {{row.LastName}}<br>{{row.Myoji}} </td>
                    </ng-container>


                    <ng-container matColumnDef="Email" class="columnSm">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                        Email</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd">
                        <!-- <a href="mailto:{{ row.Email }}" target="_top">
                          Email</a> -->
                          {{row.Email}}
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="Gender">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                        性別</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Gender=='Male'?'男性':'女性'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Age">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="age" class="columnSm">
                        年齢</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{calculate_age(row.DOB)}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Student">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="age" class="columnSm">
                        学生</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Student==true?'はい':'いいえ'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="Christian">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="age" class="columnSm">
                        クリスチャン</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Christian==true?'はい':'いいえ'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ProgramType">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="programType" class="columnSm">
                        プログラム</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.ProgramType=='JapaneseAdult'?'一般':(row.ProgramType=='RJC'?'English':row.ProgramType)}} </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="ProgramType">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="Student" class="columnSm">
                        Student</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Student==true?'はい':'いいえ'}} </td>
                    </ng-container> -->

                    <ng-container matColumnDef="SmallGroupLanguage">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="SmallGroupLanguage" class="columnSm">
                        言語</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.SmallGroupLanguage=='Japanese'?'日本語':row.SmallGroupLanguage}} </td>
                    </ng-container>

                    <ng-container matColumnDef="AttendJapanRegionalMeeting">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="AttendJapanRegionalMeeting" class="columnSm">
                        日本地域別集会</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.AttendJapanRegionalMeeting==true?'参加':'不参加'}} </td>
                    </ng-container>
                    <ng-container matColumnDef="ApplyEnglishPartner">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="ApplyEnglishPartner" class="columnSm">
                        イングリッシュパートナー</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.ApplyEnglishPartner==true?'参加':'不参加'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="ApplyCounseling">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="ApplyCounseling" class="columnSm">
                        カウンセリング</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.ApplyCounseling==true?'参加':'不参加'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="NeedCommingBus">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="NeedCommingBus" class="columnSm">
                        行きのバス</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.NeedCommingBus==true?'必要':'不要'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="NeedReturningBus">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="NeedReturningBus" class="columnSm">
                        帰りのバス</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.NeedReturningBus==true?'必要':'不要'}} </td>
                    </ng-container>

                    <!-- <ng-container matColumnDef="Dec27">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="Dec27" class="columnSm">
                        前泊</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.Dec27=="StayDec27"?'する':'しない'}} </td>
                    </ng-container> -->

                    <ng-container matColumnDef="myVolunteerSelections">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="myVolunteerSelections" class="columnSm">
                        奉仕</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd">
                      <div *ngFor="let myVolunteerSelection of row.myVolunteerSelections">
                      {{ myVolunteerSelection.VolunteerName}}
                      </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="myRelatedPersons">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header="myRelatedPersons" class="columnSm">
                        同伴者</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd">
                      <div *ngFor="let myRelatedPerson of row.myRelatedPersons">
                      {{ myRelatedPerson.FirstNameRelated}}&nbsp;{{ myRelatedPerson.LastNameRelated}}
                      </div>
                      </td>
                    </ng-container>

                    <ng-container matColumnDef="registeredDate">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">
                      登録日</th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd"> {{row.RegisteredDate | date: 'shortDate'}} </td>
                    </ng-container>

                    <ng-container matColumnDef="myEditProfile">
                      <th mat-header-cell *matHeaderCellDef mat-sort-header class="columnSm">My Account
                      </th>
                      <td mat-cell *matCellDef="let row" class="columnSmTd">
                        <button mat-button (click)="onClickGoToMyAccount(row)" class="btn btn-link editLink">My Account
                        </button>
                      </td>
                    </ng-container>


                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                    <tr class="mat-row" *matNoDataRow>
                    </tr>
                  </table>

                  <mat-paginator [pageSize]="50" [pageSizeOptions]="[5, 10, 25, 100, 200, 300, 400]"></mat-paginator>
                </div>
              </div>
            </div>
          </div>


    </div>
  </div>
</div>
